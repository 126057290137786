import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { InstaApiService } from '../../services/insta-api.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InstagramComponent {
  @ViewChild('rootElement', {static: false}) rootElement: HTMLElement;
  images = [];
  baseUrl = '/assets/img/instagram/';
  collectionNames = [
    { name: 'traveler', photos: 98, folder: 'traveler', active: false },
    { name: 'prensa', photos: 58, folder: 'prensa', active: true },
    { name: 'desfiles', photos: 213, folder: 'desfiles', active: false }
  ];

  instagram: any;
  constructor(private instaApi: InstaApiService) {
    this.instagram = {
      image: '',
      likes: 0,
      link: '',
      caption: ''
    };
    this.getInstaPhotos();
    this.getPhotos();
   }

  getPhotos() {
    if (this.images.length === 0 ) {
      this.collectionNames.forEach(element => {
        for (let i = 2; i <= element.photos; i++) {
          this.images.push({
            image: this.baseUrl + element.folder + '/' + i + '.jpg',
            folder: element.folder
          });
        }
      });
      // console.log(this.images);
      return this.images;
    }
  }
  getInstaPhotos() {
    // this.instaApi.getPhotos()
    //   .subscribe(data => {
    //     data.data.splice(1, data.data.length); 
    //     this.instagram = {
    //       image: data.data[0].images.low_resolution.url,
    //       likes: data.data[0].likes,
    //       link:  data.data[0].link,
    //       caption: data.data[0].caption.text
    //     }; 
    //   }, (error) => {
    //     console.log(error);
    //   });
    this.instagram = {
      image: '/assets/img/instagram/instagram/publicacion.jpg',
      likes: 16,
      link:  'https://www.instagram.com/p/CCTpobCJMFG/',
      caption: 'Rise and shine 💫'
    };
  }
}
