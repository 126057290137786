import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';

import { Contact } from '../interfaces/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }
  sendMail(contact: Contact) {
    return this.http.post('http://latiture.com/sendmail-latiture/send-mail.php', contact)
      .pipe(map(data => data));
  }
}
