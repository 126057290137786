import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() home: any;
  @Input() collections: any;
  @Input() instagram: any;
  @Input() about: any;
  @Input() contact: any;

  scroll(el: any) {
    console.log(el);
    el.rootElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  constructor() { }

  ngOnInit() {
    console.log(this.home);
    console.log(this.collections);
    console.log(this.instagram);
    console.log(this.about);
    console.log(this.contact);
  }

}
