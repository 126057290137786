import { Component, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @ViewChild('rootElement', {static: false}) rootElement: HTMLElement;
  constructor() { }

  ngOnInit() {
  }

}
