import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {map} from 'rxjs/operators';
import { InstagramResponse } from '../interfaces/instagram';



@Injectable({
  providedIn: 'root'
})
export class InstaApiService {

  // tslint:disable-next-line: variable-name
  private user_id: string = environment.insta_USER_ID;
  // tslint:disable-next-line: variable-name
  private access_token: string = environment.insta_ACCESS_TOKEN;

  constructor(private http: HttpClient) {}

  private instaURL: string = 'https://api.instagram.com/' +
    'v1/users/' + this.user_id +
    '/media/recent/' +
    '?count=99' +
    '&callback=JSONP_CALLBACK' +
    '&access_token=' + this.access_token;

  getPhotos() {
    return this.http.jsonp<InstagramResponse>(this.instaURL, 'JSONP_CALLBACK')
      .pipe(map(data => data));
  }

  // private extractData(res: Response) {
  //   const body = res.json();
  //   return body.data || { };
  // }

  // private handleError (error: any) {
  //   // In a real world app, we might use a remote logging infrastructure
  //   // We'd also dig deeper into the error to get a better message
  //   const errMsg = (error.message) ? error.message :
  //     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
  //   console.error(errMsg); // log to console instead
  //   return Observable.throw(errMsg);
  // }
}
