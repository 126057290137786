import { Component, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('rootElement', {static: false}) rootElement: HTMLElement;
  slides = [
    {
      img: '/assets/img/principal/slide-1.jpg',
      text: 'RESORTWEAR',
      routerLink: '/home',
      active: true
    },
    {
      img: '/assets/img/principal/slide-2.jpg',
      text: 'RESORTWEAR',
      routerLink: '/home'
    },
    {
      img: '/assets/img/principal/slide-3.jpg',
      text: 'RESORTWEAR',
      routerLink: '/home'
    },
    {
      img: '/assets/img/principal/slide-4.jpg',
      text: 'RESORTWEAR',
      routerLink: '/home'
    },
    {
      img: '/assets/img/principal/slide-5.jpg',
      text: 'RESORTWEAR',
      routerLink: '/home'
    },
    {
      img: '/assets/img/principal/slide-6.jpg',
      text: 'RESORTWEAR',
      routerLink: '/home'
    }
  ];

  constructor() { }

  ngOnInit() {
  }
  getItemClass(slide) {
    if (slide.active) {
      return 'carousel-item active';
    }
    return 'carousel-item';
  }
  getActiveClass(slide) {
    if (slide.active) {
      return 'active';
    }
    return '';
  }

}
