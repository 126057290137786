import { Component, OnInit, ViewChild } from '@angular/core';
import { Contact } from '../../interfaces/contact';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;
  contacto: Contact = { name: '', email: '', message: '' };
  constructor(private apiContacto: ContactService) { }
  ngOnInit() {
  }
  sendMail() {
    console.log('sendMail');
    this.apiContacto.sendMail(this.contacto);
  }
}
