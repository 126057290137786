import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { CollectionsComponent } from './pages/collections/collections.component';
import { InstagramComponent } from './pages/instagram/instagram.component';
import { ContactComponent } from './pages/contact/contact.component';


const routes: Routes = [
  {path: 'home', component: HomeComponent },
  {path: 'about', component: AboutComponent},
  {path: 'collections', component: CollectionsComponent},
  {path: 'instagram', component: InstagramComponent},
  {path: 'contact', component: ContactComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
