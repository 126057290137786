import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})

export class CollectionsComponent implements OnInit {
  currentIndex = 0;
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;
  baseUrl = '/assets/img/collections/';
  collections = null;
  collectionNames = [
    { name: 'Resortwear', photos: 35, folder: '1', active: true },
    { name: 'Spring Tea Time', photos: 13, folder: '2', active: false },
    { name: 'Cotton Farm', photos: 11, folder: '3', active: false },
    { name: 'Deborah\'s Army', photos: 9, folder: '4', active: false },
    { name: 'Fifty Shades', photos: 13, folder: '5', active: false },
    { name: 'Geisha', photos: 16, folder: '6', active: false },
    { name: 'Giorti', photos: 12, folder: '7', active: false },
    { name: 'Origami', photos: 15, folder: '8', active: false },
    { name: 'Bamboo Forest', photos: 10, folder: '9', active: false },
    { name: 'Wild Summer', photos: 15, folder: '10', active: false }
  ];
  getCollections() {
    if (this.collections == null) {


      this.collections = this.collectionNames.map((currElement) => {
        return {
          name: currElement.name,
          folder: currElement.folder,
          images: [
            this.baseUrl + currElement.folder + '/1.jpg',
            this.baseUrl + currElement.folder + '/2.jpg',
            this.baseUrl + currElement.folder + '/3.jpg'
          ],
          fullCollection: this.getPhotos(currElement, currElement.folder),
          active: currElement.active
        };
      });
    }
    return this.collections;
  }
  getPhotos(currCollection, folder) {
    const data = [];
    for (let i = 5; i <= currCollection.photos; i++) {
      data.push(this.baseUrl + folder + '/' + i + '.jpg');
    }
    return data;
  }

  constructor() { }

  ngOnInit() {
  }
  getItemClass(slide) {
    if (slide.active) {
      return 'carousel-item active';
    }
    return 'carousel-item';
  }
  getActiveClass(slide) {
    if (slide.active) {
      return 'active';
    }
    return '';
  }
  carouselPrev() {
    console.log('prev');
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.collections.length - 1;
    }
  }
  carouselNext() {
    console.log('next');
    this.currentIndex++;
    if (this.currentIndex >= this.collections.length) {
      this.currentIndex = 0;
    }
  }
}
